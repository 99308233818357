.home-about{
   height: auto;
   min-height: 400px;
   height: auto;
   margin: 95px 0;
   
   h1{
      font-size: 36px;
      span{
         display: block;
         font-size: 18px;
         font-weight: bold;
      }
   }
   .home-about-intro{
      padding: 3rem;
   }

   .croocked{
      writing-mode:vertical-rl;
      transform:scale(-1);
      font-size: 144px;
      display: block;

   }
}