@media screen and (max-width:768px){
   footer{
      .accent-image{
         // display: none;
         height: 100%;
         opacity: 0.4;
         width: auto;
      }
   }
}


@media screen and (max-width:576px){
   .navToggler{
      display: block;
   }
   .navigation{
      height: 0;
      background: #000;
      transition: ease .3s all;

      .navbarList{
         margin: 0;
         padding: 0;
         opacity: 0;
         transition: ease .3s opacity;
         li{
            float: none;
            width: 100%;

            a{
               font-size: 25px;
            }
         }
      }

      &.active{
         height: 100vh;

         .navbarList{
            opacity: 1;
         }
      }
   }

   .header{
      &.homeHeader{
         height: 80vh;

         h2{
            font-size: 20px;
         }
         h3{
            font-size: 18px;
         }
         .headerText{
            p{
              font-size: 14px; 
            } 
         }
         .intro-image{
            max-width: 90%;
         }
      }
   }

   .home-about{
      min-height: 400px;
      margin: 25px 0;
      text-align: center;

      h1{
         font-size: 25px;
         font-weight: bold;

         span{
            font-size: 14px;
         }
      }

      .home-about-intro{
         padding: 15px;
      }

      .croocked{
         display: none;
      }
   }

   .logo{
      top: -52px;
      
      img{
         max-width: 65px;

      }
   }
   .scrolled{
      .logo{
         top: -52px;
      }
   }

   #about{
      .big-title{
         font-size: 60px;
         line-height: 50px;
      }
   }

   .big-title{
      font-size: 62px;
   }

   .map{
      margin-top: 45px;
   }

   .header-intro{
      figure{
         display: none;
      }
   }

   .contactBanner{
      text-align: center;
      .button{
         margin-top: 25px;
         display: block;
      }
   }

   footer{
      text-align: center; 
      padding: 45px 0;
      .hours{
         margin-left: auto;
         margin-right: auto;
         text-align: left;
      }
   }

}