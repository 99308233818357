.contactBanner{
   padding: 45px 0;
   height: auto;

   h2{
      font-weight: bold;
      font-size: 38px;
      letter-spacing: -1px;
   }
}

.contact-container{
   background: #fff;
}

.contact-header{
   position: relative;
   width: 100%;
   background-image: url(../images/contact_header.png);
   background-position: center;
   background-size: cover;
   iframe{
      position: absolute;
      right: 0;
      top: 0;
      width:60%;
      height: 100%;
      z-index: 0;
   }
   .overlay{
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(90.59deg, #DD8E58 0.19%, #DD8E58 42.76%, rgba(221, 142, 88, 0) 99.8%);
      z-index: 0;
      width:100%;
      height: 100%;
      pointer-events:none;
   }
}

.no-clicks{
   pointer-events: none;
}