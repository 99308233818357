.navToggler{
   display: none;
   height: 45px;
   padding: 7px 0 0 20px;
}


.navbar{
   min-height: 45px;

   .navToggler{
      &.active{
         span{
            &.first{
               margin-bottom: -6px;
               width: 38px;
            }
            &.second{
               width: 38px;
            }
            &.third{
               margin-top: -6px;
               width: 38px;
            }
         }
      }
      span{
         height: 3px;
         background: $accent-color-light;
         display: block;
         margin: 5px 0;
         width: 38px;
         transition: ease .3s all;

         &.third{
            width: 34px;
         }
         &.second{
            width: 30px;
         }
      }
   }

   .navigation{
      width: 100%;
   }

   ul{
      margin: 0;
      padding: 12px 0;
      transition: ease .3s all;
      li{
         float: left;
         list-style: none;
         a{
            color: white;
            &.current{
               color: $accent-color-light;
               font-weight: bold;;
            }
         }
      }
   }
}

.scrolled{
   .navbar{
      ul{
         padding: 5px 0;
      }
   }

   .logo{
      top: -7px;

      right: 5px;
      transition: ease .3s all;


      img{
         max-width: 65px;
      }
   }
}

.logo{
   top: -7px;
   position: absolute;
   right: 5px;
   transition: ease .3s all;


   img{
      max-width: 65px;
   }
}