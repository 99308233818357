.my-masonry-grid {
   display: -webkit-box; /* Not needed if autoprefixing */
   display: -ms-flexbox; /* Not needed if autoprefixing */
   display: flex;
   margin-left: -30px; /* gutter size offset */
   width: auto;
 }
 .my-masonry-grid_column {
   padding-left: 10px; /* gutter size */
   background-clip: padding-box;
 }
  
 /* Style your items */
 .my-masonry-grid_column > img { /* change div to reference your elements you put in <Masonry> */
   background: grey;
   margin-bottom: 10px;
   border-radius: 4px;
 }

 @media screen and (max-width:576px){
   .my-masonry-grid {
      margin-left: 0px; /* gutter size offset */
      padding: 0;
    }
 }

 .slideIn{
    opacity: 0;
 }