$accent-color:          #513A34;
$accent-color-light:    #DD8E58;
$offset-white:    #ddd;

.bg-black{
   background: #000;
}
.bg-accent{
   background: $accent-color-light;
}

.color-white{
   color: #fff;
}

.accent-light{
   color: $accent-color-light;
}
.accent-dark{
   color: $accent-color;
}

.bg-fb{
   background: #3b5998;
}