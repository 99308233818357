@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@1,700&family=Roboto:wght@300;400&display=swap');
body{
   font-family: 'Roboto', sans-serif;
}
.np{
   padding: 0;
   margin: 0;
}
.deco-font{
   font-family: 'Montserrat', sans-serif;
}
.semi{
   font-weight: normal;
}
figure{
   margin: 0;
}
.button{
   padding: 12px 18px;
   border-radius: 12px 0px;
   box-sizing: border-box;
   font-weight: bold;
   transition: ease .3s all;
   border: none;
   cursor: pointer;

   &.dark{
      background: $accent-color;
      color: $accent-color-light;
   }

   &.light{
      background: $accent-color-light;
      color: $accent-color;
   }

   &.black{
      background: #000;
      color: $accent-color-light;
   }

   &:hover{
      text-decoration: none;
      border-radius: 0;
   }
}

.fb-button{
   position: relative;
   color: #fff;

   span{
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      transition: ease .3s all;
   }

   img{
      margin-left: 15px;
   }

   &:hover{
      span{
         width: 60px;
         right: 0;
         transition: ease .3s all;

      }
      img{
         margin-left: 20px;
      }
   }
}

.big-title{
   font-size: 90px;
   font-weight: bold;
   line-height: 80px;
}

.no-header{
   height: auto;
   margin-top: 60px;
   padding-top: 105px;

   &.small{
      min-height: 400px;
   }
   &.medium{
      height: 500px;
   }
}

.header{
   width: 100%;
   height: 100vh;
   max-height: 1080px;
   background-size: cover;

   &.homeHeader{
      // background-image: url(../images/home_header.png);
      // background-position: bottom;
      position: relative;
      background: #000;

      .intro-image{
         position: absolute;
         bottom: 0;
         left: 0;
         animation: slide-in 1.5s;
         width: 100%;
         max-width: 1000px;
         img{
            max-width: 100%;
         }
      }
   }

   .header-intro{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      h2{
         margin-bottom: 15px;
      }
      h3{
         line-height: 90%;
      }
      .headerText{
         margin-top: 25px;
         
         p{
            margin-bottom: 0;
            font-size: 18px;
         }
      }
      h4{
         font-size: 22px;
         margin-top: 25px;
         font-weight: bold;
      }
   }
}

.list-inline{
   li{
      display: inline;
      margin-right: 25px;
   }
}


.imageBanner{
   background-image: url(../images/imageBanner.png);
   background-size: cover;
   min-height: 500px;
   animation: slideImage 8s infinite;
}

@keyframes slideImage{
   0%{background-position: left;}
   90%{background-position: right;}
   100%{background-position: left;}
}

@keyframes slide-in{
   0%{
      left: -30%;
      opacity: 0;
   }
   100%{
      left: 0%;
      opacity: 1;

   }
}


.opacityZero{
   opacity: 0;
}