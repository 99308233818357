.par-img{
   border-radius: 4px;
   box-shadow: black;
}

.about-header{
   background-image: url('../images/about_banner.png');
}


.fadeIn,
.fadeInTwo,
.fadeInThree{
   opacity: 0;
}

.divider{
   width: 100%;
}
.d-160{
   height: 160px;
}

.about_video{
   width: 100%;
}